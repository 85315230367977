<template>
  <validation-provider
    :rules="rules"
    :name="`«${name}»`"
    v-slot="v"
    tag="div"
    :vid="vid"
  >
    <div
      :class="{
        'calculated-field': true,
        'hm-form__element-wrapper': true,
        'hm-form__element-wrapper--error': v.errors.length
      }"
    >
      <div class="hm-form__element-label">
        {{ name }}
        <span v-if="required">*</span>
      </div>
      <div class="calculated-field__calculate">
        <esmp-button
          view="outline"
          size="small"
          :disabled="isLoading"
          @click="calculate"
        >
          Рассчитать
        </esmp-button>
        <esmp-loader v-if="isLoading" />
        <esmp-input
          v-show="isNumber(localValue) && !isLoading"
          class="calculated-field__result"
          v-model="localValue"
          readonly
        />
      </div>
      <div v-if="v.errors.length" class="hm-form__element-hint">
        {{ v.errors[0] }}
      </div>
    </div>
  </validation-provider>
</template>

<script>
import { mapState } from 'vuex';
import isNumber from 'lodash/isNumber';
import Hub from '@/plugins/event-hub';
import { getValueDeepByKey } from '@/utils/findDeep';
import uid from '@/components/hm-form/mixins/uid';

export default {
  name: 'HmCalculatedField',
  mixins: [uid],
  props: {
    value: {
      type: Number,
      default: undefined,
    },
    id: {
      type: [String, Number],
      default: undefined,
    },
    required: {
      type: Boolean,
      default: false,
    },
    rules: {
      type: [String, Object],
      default: '',
    },
    name: {
      type: String,
      default: undefined,
    },
    dependedFieldIds: {
      type: Array,
      default: undefined,
    },
  },
  data() {
    return {
      isLoading: false,
      prevDependedFields: null,
    };
  },
  computed: {
    ...mapState('ticket', ['form']),
    dependedFields() {
      const list = {};
      this.dependedFieldIds.forEach((id) => {
        const value = getValueDeepByKey(this.model, id);
        if (value) list[id] = value;
      });
      return list;
    },
    localValue: {
      get() {
        return this.value;
      },
      set(newValue) {
        this.$emit('input', newValue);
      },
    },
    model() {
      return this.$options.parent.model;
    },
  },
  watch: {
    dependedFields: {
      handler() {
        const isNotEqual = this.dependedFieldIds.find((id) => this.prevDependedFields[id] !== this.dependedFields[id]);
        if (isNotEqual) this.localValue = null;
        this.prevDependedFields = { ...this.dependedFields };
      },
      deep: true,
    },
  },
  methods: {
    isNumber,
    calculate() {
      Hub.$emit('get-form-data-for-calc');

      this.isLoading = true;

      const localChecklistItems = this.form?.checklistItems.map((item) => {
        // eslint-disable-next-line no-param-reassign
        if (item.classType === 'input') item.value = item.value.replace(/,/, '.');

        return item;
      });

      this.$API.controls.calculateFormulaResult({
        sourceSystem: 'otrs1',
        formId: this.form?.formId,
        formVersionId: this.form?.formVersionId,
        checklistItemId: this.id,
        checklistItems: localChecklistItems,
        formType: this.form?.formType,
      })
        .then(({ data }) => {
          this.localValue = data;
        })
        .finally(() => {
          this.isLoading = false;
        });
    },
  },
  created() {
    this.prevDependedFields = { ...this.dependedFields };
  },
};
</script>
<style lang="scss">
.calculated-field {
  &__calculate {
    display: flex;
    align-items: center;
    gap: $base-gutter;
  }
}
</style>
